import styled from "styled-components"
import GridItem from "../../components/molecules/GridItem/GridItem"
import FacebookNews from "../../components/organisms/FacebookNews/FacebookNews"

export const Grid = styled.div`
    padding: 100px 0;
    
    ${({ theme }) => theme.mq.desktop} {
        display: grid;
    }
`
export const StyledGridItem = styled(GridItem)`
    margin: 64px 0;
    padding: 0 32px;

    ${({ theme }) => theme.mq.desktop} {
        margin: 64px auto;
        padding: 100px 0;
        margin: ${({ reversed }) => reversed ? "64px auto 0 0" : "64px 0 0 auto"};
        width: 90%;
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 80%;
    }
`

export const GridItemImages = styled.div`
    margin: 3.2rem 0;

    ${({ theme }) => theme.mq.desktop}{
        margin: 64px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
    } 
`

export const StyledHeading = styled.h2`
    text-align: center;
    padding: 0 32px;
    grid-row: ${({order}) => order};

    ${({ theme }) => theme.mq.desktop} {
        width: 60%;
        margin: 0 auto;
        font-size: 3.2rem;
    }
`

export const StyledImage = styled.img`
    width: 100%;
`

export const TextContainer = styled.div`
    width: 100%;

    ${({ theme }) => theme.mq.desktop } {
        margin: 64px auto;
        width: 90%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 5%;
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 80%;
        column-gap: 10%;
    }
`

export const StyledFacebookNews = styled(FacebookNews)`
    padding: 0 32px;
    margin-top: 64px;

    ${({theme}) => theme.mq.desktop} {
        padding: 0;
        width: 90%;
        margin: 64px auto 0 auto;
    }

    ${({ theme }) => theme.mq.widescreen} {
        width: 80%;
    }
`

export const ListContainer = styled.div`
    padding: 0 32px;
    margin: 3.2rem 0;
`