import React from "react"

import MainTemplate from "../MainTemplate/MainTemplate"

import pobf5 from "../../images/pobf/pobf-8.jpg"
import pobf6 from "../../images/pobf/pobf-6.jpg"
import Footer from "../../components/organisms/Footer/Footer"
import Hero from "../../components/organisms/Hero/Hero"

import {
  Grid,
  StyledGridItem,
  GridItemImages,
  StyledHeading,
  StyledImage,
  TextContainer,
  StyledFacebookNews,
  ListContainer,
} from "./PobfTemplate.styles"

const PobfTemplate = ({ heroImage, sections }) => (
  <MainTemplate>
    <Hero
      title="Park Ochrony Bieszczadzkiej Fauny - idea i cele projektu"
      images={heroImage}
      subpage
    />
    <Grid>
      <StyledHeading>
        Park Ochrony Bieszczadzkiej Fauny to unikalny projekt placówki będącej
        kompromisem pomiędzy tradycyjnym ogrodem zoologicznym, a kliniką dla
        zwierząt.
      </StyledHeading>
      {sections.map((section, index) =>
        index % 2 ? (
          <StyledGridItem
            key={index}
            title={section.title}
            text={section.text}
            image={section.image}
            reversed
          />
        ) : (
          <StyledGridItem
            key={index}
            title={section.title}
            text={section.text}
            image={section.image}
          />
        )
      )}

      <StyledHeading order={4}>
        Takiego projektu nie ma w całych Bieszczadach, na Podkarpaciu, ani nawet
        w Polsce
      </StyledHeading>

      <GridItemImages>
        <StyledImage src={pobf5} />
        <StyledImage src={pobf6} />
      </GridItemImages>
      <StyledHeading>
        Główne idee przyświecające realizacji Bieszczadzkiego Parku Ochrony
        Bieszczadzkiej Fauny to:
      </StyledHeading>
      <TextContainer>
        <ListContainer>
          <ul>
            <li>
              utworzenie warunków bytowych dla zwierząt dzikich zbliżonych
              maksymalnie do naturalnych,
            </li>
            <li>
              utworzenie ostoji dla zwierząt dzikich, które uległy różnego
              rodzaju wypadkom, negatywnym działaniom antropogenicznym lub
              chorobom, np. potrącone przez samochód, otrute, uratowane z
              wnyków, zranione,
            </li>
            <li>
              zapewnienie rehabilitacji i specjalistycznej opieki, mającej na
              celu późniejszą reintrodukcję czyli wypuszczenie do środowiska
              naturalnego po uzyskaniu pożądanego stanu zdrowotnego i pełnej
              samodzielności,
            </li>
          </ul>
        </ListContainer>
        <ListContainer>
          <ul>
            <li>
              zapewnienie warunków bytowania na terenie parku osobnikom, które
              nie nadają się do reintrodukcji (np. poprzez zbyt długie
              przebywanie w pobliżu ludzi lub gdy powrót do środowiska
              naturalnego nie jest możliwy z powodu posiadanych urazów),
            </li>
            <li>
              zapewnienie możliwości rozmnażania się zwierzętom, mające na celu
              późniejsze wprowadzanie do środowiska naturalnego osobników
              szczególnie tych zagrożonych wyginięciem, metodą "Born To Be
              Free",
            </li>
            <li>
              prace dydaktyczne mające na celu przedstawienie biologii
              zagrożonych gatunków zwierząt i unikatowej bioróżnorodności
              mezoregionu Bieszczad, jej walory, zagrożenia jak i metod jej
              ochrony.
            </li>
          </ul>
        </ListContainer>
      </TextContainer>
    </Grid>
    <StyledFacebookNews />
    <Footer />
  </MainTemplate>
)

export default PobfTemplate
