import React from "react"
import { graphql } from "gatsby"

import PobfTemplate from "../templates/PobfTemplate/PobfTemplate"

const ParkOchronyBieszczadzkiejFauny = ({ data }) => {
  const heroSources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const sections = [
    {
      id: 0,
      title: "",
      text:
        "Park przewiduje legalne przetrzymywanie zwierząt występujących w Bieszczadach również będących pod ochroną, min. żbiki, puchacze, puszczyki uralskie, orły, kruki i inne gatunki ptaków oraz rysie, jenoty, jelenie, wilki, a nawet niedźwiedzie. Zaletą tego przedsięwzięcia jest jego umiejscowienie w samym sercu Bieszczadów, a więc zapewnienie zwierzętom warunków maksymalnie zbliżonych do naturalnych.",
      image: data.pobf1.childImageSharp.fluid,
    },
    {
      id: 1,
      title: "",
      text:
        "Dla potrzeb realizacji projektu przeznaczono 5 hektarów terenu w większej części naturalnie zalesionego, o zróżnicowanym ukształtowaniu wraz z naturalnymi potokami. Park położony jest w miejscowości Olchowiec, Gmina Czarna, pomiędzy wodami zalewu solińskiego a dzikim pasmem Bieszczadów – Otrytem, w bezpośrednim sąsiedztwie obszaru Natura 2000, na terenie stanowiącym integralną część kompleksu wypoczynkowego Villa Collis (www.villacollis.pl). Całość projektu realizowana jest we współpracy z wykwalifikowaną kadrą naukową, lekarzem weterynarii, Starostwem Powiatowym w Ustrzykach Dolnych, Regionalną Dyrekcją Ochrony Środowiska w Rzeszowie oraz Bieszczadzką Organizacją Ochrony Zwierząt - instytucje te zostały powiadomione o zamiarze realizacji tego projektu i wyraziły się o przedsięwzięciu bardzo pozytywnie. Nadmienić należy, że takiego projektu nie ma w całych Bieszczadach, na Podkarpaciu, ani nawet w Polsce.",
      image: data.pobf2.childImageSharp.fluid,
    },
    {
      id: 2,
      title: "",
      text:
        "Bieszczady są największą ostoją zwierząt, które chcemy objąć projektem - brak takiej placówki jak określona w naszym projekcie powoduje bardzo duże problemy z możliwością niesienia pomocy zwierzętom jej potrzebującym, a w wielu przypadkach absolutną niemoc. Znanych jest wiele przypadków, kiedy jedyną formą pomocy wobec odnalezionych w Bieszczadach zwierząt jest po prostu oddanie takiego zwierzęcia do odległego o kilkaset kilometrów ogrodu zoologicznego.",
      image: data.pobf3.childImageSharp.fluid,
    },
  ]

  return <PobfTemplate heroImage={heroSources} sections={sections} />
}

export const query = graphql`
  {
    mobileImage: file(name: { eq: "pobf-hero-mobile" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(name: { eq: "pobf-hero-desktop" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    pobf1: file(name: { eq: "pobf-1" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    pobf2: file(name: { eq: "pobf-2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    pobf3: file(name: { eq: "pobf-3" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default ParkOchronyBieszczadzkiejFauny
