import React from "react"
import styled from "styled-components"
import TextStack from "../../molecules/TextStack/TextStack"

const StyledWrapper = styled.div`

    ${({ theme }) => theme.mq.desktop} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20%;
    }
`

const FacebookNews = ({className}) => (
    <StyledWrapper className={className}>
        <TextStack span="Aktualności" title="Sprawdź najnowsze aktualności" paragraph="To coś więcej niż hotel - to miejsce, do którego zawsze chce się wracać." />
            <iframe title="facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fparkochrony%2F&tabs=timeline&width=300&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1362498703933293" width="300" height="500" style={{border: "none", overflow:"hidden"}} scrolling="no" frameBorder="0" allow="encrypted-media"></iframe>
    </StyledWrapper>
)

export default FacebookNews